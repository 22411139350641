import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "more-documentation-coming-soon"
    }}>{`More Documentation Coming Soon`}</h1>
    <hr></hr>
    <p>{`We Are always trying to improve and document more wonderful things that can be done with R.A.D. Routes.`}</p>
    <p>{`If there is something not here yet that you expect, it will be coming soon. `}</p>
    <p>{`If you cannot wait, contact us at `}<a parentName="p" {...{
        "href": "mailto:radrouterun@gmail.com"
      }}>{`radrouterun@gmail.com`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      